@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
html {
  background-color: #eeeeee;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
