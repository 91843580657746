@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap");
@import url("https://use.typekit.net/run8zql.css");

.contentBox {
  font-size: 14px;
  text-align: justify;
}

#alert-dialog-title {
  font-size: 20px;
}

.MuiAlert-message {
  font-size: 14px;
  .MuiAlertTitle-root {
    font-weight: 400;
    font-size: 16px;
  }
}

.MuiDialog-root,
#wljContainer {
  font-family: "Poppins";
  font-size: 16px;
  line-height: normal;

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .MuiFormHelperText-root {
    font-size: 12px;
  }

  .sectionHeading {
    font-family: "canada-type-gibson";
    font-size: 36px;
    font-weight: 600;
    letter-spacing: -0.02em;
    color: #2d3047;
  }

  .sectionSubHeading {
    font-family: "canada-type-gibson";
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.02em;
  }

  .dialogContent {
    font-family: "Poppins";
    font-size: 16px;
    line-height: normal;
  }

  .MuiButton-root {
    font-size: 14px;
    font-weight: 500;
  }

  .dialogCloseButton {
    font-size: 14px;
  }

  .submitButton {
    border-radius: 60px;
    text-transform: none;
    line-height: 31.5px;
    font-size: 18px;
  }

  p {
    margin-left: 0;
    margin-right: 0;
  }

  .fieldLabel {
    padding-bottom: 16px;
  }

  input {
    font: inherit;
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    display: block;
    min-width: 0;
    padding: 16.5px 10px;
    font-size: 16px;
  }
}

.optionField {
  .optionButton {
    font-size: 15px;
    font-weight: 500;
  }
}

.summaryField {
  .summaryFieldTitle {
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 700;
    letter-spacing: normal;
  }
}

#wljContainer {
  #wljHeader {
    padding-bottom: 32px;
    line-height: normal;
    letter-spacing: normal;

    h1 {
      font-family: "Poppins";
      font-weight: 700;
      font-size: 32px;
      line-height: normal;
      letter-spacing: normal;
    }

    #paymentText {
      font-family: "Poppins";
      font-size: 18.72px; // I don't know why it's this specific
      font-weight: 700;
    }

    #headerGbpSymbol {
      #symbol {
        font-size: 36px;
      }

      #amount {
        font-size: 48px;
      }
    }
  }

  .brandingLogo {
    max-width: 300px;
  }

  @media (max-width: 500px) {
    .brandingLogo {
      max-width: 200px;
    }
  }

  .htmlFooterField {
    background: none;
    border: none;
    margin: 16;
    color: "#707070";
    text-align: center;
    font-size: small;
  }

  .shopfront {
    /* Header/H2 */
    font-family: "canada-type-gibson";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    color: #2d3047;
  }

  .card {
    min-height: fit-content;
    min-width: 100%;
    padding: 24px;
    background: #ffffff;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    max-width: 360px;
  }

  .cardHeader {
    width: 100%;
    font-family: "canada-type-gibson";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #2d3047;
  }

  .cardImage {
    width: 100%;
    height: 180px;
    background: #c4c4c4;
    border-radius: 7px;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .cardText {
    width: "100%";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: auto !important;
    text-align: justify;
    color: #2d3047;
  }

  .cardSubtitle {
    align-self: flex-start;
    margin-top: 0px !important;
    font-size: 14px;
    font-style: normal;
    color: #2d3047;
    font-weight: 500;
    line-height: 18px;
  }

  .headerSubtitle {
    margin-left: 3px;
    font-size: 15px;
    font-weight: 500;
  }

  .cardButton {
    width: 100%;
    height: 54px;
    // background: #5ba72e;
    border-radius: 8px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  .htmlField {
    overflow: auto;
    max-height: 200px;
    border: 1px solid black;
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    background-color: white;
  }

  .htmlFieldWithNoBackgroundColor {
    overflow: auto;
    max-height: 200px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .htmlField ul {
    margin-left: 16px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  .journeyContainer {
    position: relative;
    margin-top: -16px;
    padding-top: 24px;
    padding-bottom: 40px;
    color: #2d3047;
    background-color: #f4f4fb;
    border-radius: 20px 20px 0 0;
    line-height: 1.5;
  }

  .journeyContainerEnd {
    color: #2d3047;
    background-color: #f4f4fb;
    border-radius: 0 0 20px 20px;
    padding-bottom: 24px;
    line-height: 1.5;
  }

  .quoteTop {
    margin-top: -16px;
    padding-top: 24px;
    padding-bottom: 40px;
    background-color: white;
    border-radius: 20px 20px 0 0;
    line-height: 1.5;
  }

  .fieldLabel {
    padding-bottom: 16px;
  }

  .MuiOutlinedInput-root {
    background-color: white;
  }

  .InfoField {
    cursor: pointer;
  }

  /* JSON Viewer (EDIT MODE) */
  .json-viewer {
    font-family: "Courier New", monospace;
    font-size: 14px;
    line-height: 1.4;
    background-color: #f5f5f5;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 400px;
    user-select: all;
    overflow-y: auto;
  }
}
